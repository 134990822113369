import React, { Fragment, useCallback, useState } from 'react';

import { IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from 'file-saver';
import { fetchAPI } from '../../../api/request';
import Loading from '../../../components/common/Loading.component';
import Alert from '../../common/Alert.component';

const IconDownlaodComponent = ({ title, value, module: { tag, query } }) => {
  const { jwt } = useSelector((state) => state.login);

  const userAuth = useSelector((state) => state.login?.user);
  const hasPermissions =
    tag &&
    userAuth.permissions[tag?.toUpperCase()]?.DOWNLOAD_DOCUMENTS === 'true';
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onError = useCallback((error) => {
    setLoading(false);
    setError(error);
    // eslint-disable-next-line
  }, []);

  const onButtonClick = async () => {
    if (hasPermissions) {
      setLoading(true);
      setError(null);
      fetchAPI(query.PDF.settings, { id: value?.id }, {}, {}, jwt)
        .then((resp) => handleDownload(resp))
        .catch(onError);
    }
  };

  const handleDownload = async (resp) => {
    setError(false);
    setLoading(false);
    const pdfBlob = new Blob([resp], { type: 'application/pdf' });
    const PDF = `${value?.attributes?.report_number}.pdf`;
    saveAs(pdfBlob, PDF);
  };

  return (
    <Fragment>
      {loading && <Loading />}
      {error && (
        <Alert severity='error'>
          {error?.message
            ? error?.message
            : 'An error has occurred'}
        </Alert>
      )}

      {hasPermissions && (
        <Tooltip title={title}>
          <IconButton color='primary' onClick={onButtonClick}>
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      )}
    </Fragment>
  );
};

export default IconDownlaodComponent;

IconDownlaodComponent.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  module: PropTypes.object,
};
