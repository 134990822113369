import dayjs from 'dayjs';
import Text from '../../../components/detail/components/Text.component';
import moment from 'moment';
import { grey } from '@mui/material/colors';


export const DetailConf = {
  initialState: {
    loading: false,
    error: null,
    data: null,
    meta: null,
  },
  fields: [
    {
      id: 'reports',
      type: 'section',
      label: 'Report Number',
      sx: { backgroundColor: '#e8eaf6CC' },
      sxTypography: {
        backgroundColor: '#c5cae9',
        fontSize: 14,
        fontWeight: 'bold',
        color: grey[700],
      },

      items: [
        {
          type: 'text',
          label: '',
          key: 'attributes.report_number',
        },

        {
          type: 'text',
          label: 'Users technicians',
          key: 'attributes.users_technicians',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Work date',
          key: 'attributes.work_date',
          xs: 12,
          sm: 6,
        },

        {
          type: 'text',
          label: 'Yacht contact',
          key: 'attributes.yacht_contact',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Location',
          key: 'attributes.location',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Time in',
          key: 'attributes.time_in',
          xs: 12,
          sm: 6,
        },
        {
          type: 'text',
          label: 'Time out',
          key: 'attributes.time_out',
          xs: 12,
          sm: 6,
        },
        {
          type: 'divider',
          label: '',
          key: 'attributes.report_number',
          xs: 12,
        },
        {
          type: 'text',
          label: 'Work description',
          key: 'attributes.work_description',
          xs: 12,
          md: 6,
        },
        {
          type: 'text',
          label: 'Work note',
          key: 'attributes.work_note',
          xs: 12,
          md: 6,
        },
        {
          type: 'divider',
          label: '',
          key: 'attributes.report_number',
          xs: 12,
        },
      ],
    },
  ],
  formatValue: (key, value) => {
    switch (key) {
      case 'attributes.work_date':
        return (
          <Text label={'Work date'} value={dayjs(value).format('DD-MM-YYYY')} />
        );
      case 'time_in':
        <Text
          label={'Time in'}
          value={moment(value, 'HH:mm:ss').format('HH:mm')}
        />;
        return;
      case 'time_out':
        <Text
          label={'Time out'}
          value={moment(value, 'HH:mm:ss').format('HH:mm')}
        />;
        return;
      case 'attributes.work_description':
      case 'attributes.work_note':
        return (
          <Text
            sx={{ paddingRight: 3 }}
            label={
              key === 'attributes.work_description'
                ? 'Work description'
                : 'Work note'
            }
            value={value.split('\n').map((line, index) => (
              <span
                key={index}
                style={{ textAlign: 'justify', display: 'block' }}
              >
                {line}
                <br />
              </span>
            ))}
          />
        );

      case 'attributes.report_number':
        return <></>;
      default:
        return null;
    }
  },
};
