import axios from 'axios';
import _ from 'lodash';
import { store } from '../../redux/store';

const interpolateRegex = /\{\{(\S+?)\}\}/g;

export const fetchAPI = (
  settings,
  params = {},
  query = '',
  body = {},
  token = null
) => {
  const {
    // basePath = '',
    path = '',
    method = 'GET',
    get,
    fallback,
    adapter,
    transform,
    responseType,
    //auth = false,
    //...options
  } = settings;
  const paramsInterpolator = _.template(path, {
    interpolate: interpolateRegex,
  });
  const interpolatedPath = paramsInterpolator(params);

  const { from, to } = store.getState().year;

  switch (_.split(_.split(path, '/')[1], '?')[0]) {
    case 'users-crews':
    case 'yachts':
    case 'bookings':
    case 'attendances':
    case 'logbooks':
    case 'operation-lists':
      query = {
        ...query,
        'filters[createdAt][$gte]': from,
        'filters[createdAt][$lte]': to,
      };
      break;
    default:
      break;
  }

  let headers = {};
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  if (adapter) {
    body = adapter(body);
  }

  return axios({
    baseURL: process.env.REACT_APP_BASE_URL,
    method,
    url: interpolatedPath,
    params: query,
    data: body,
    headers,
    responseType,
  }).then((response) => {
    if (typeof response.data === 'undefined') {
      return fallback;
    }

    return [
      get &&
        function (data) {
          if (get) {
            return _.get(data, get, fallback);
          }
          return data;
        },
      transform instanceof Function &&
        function (data) {
          return transform(data, response, settings);
        },
    ]
      .filter(Boolean)
      .reduce((data, fn) => fn(data), response.data);
  });
};
