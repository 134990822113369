import moment from 'moment';
import Text from '../../../components/detail/components/Text.component';
import _ from 'lodash';
import { Link,  } from 'react-router-dom';
import {  Grid, } from '@mui/material';
import DownlaodComponent from '../../../components/detail/components/Download.component';
import { CommonDetailConf as YachtFields } from '../../yachts/conf/Detail.conf';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import LinkProductsList from '../../../components/item/button/ButtonProductsList.component';
import LinkPermission from '../../../components/detail/components/LinkPermission.component';
import RectangleChip from '../../../components/common/RectangleChip.component';
import { Status } from '../../booking/conf/Data.conf';
import { AgencyOptions } from '../../agency/conf/Data.conf';
import { ServiceOptions } from '../../interiors/conf/Data.conf';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { ShippingOptions } from '../../shipping/conf/Data.conf';

const ReservationFields = [
	'reservation_type',
	'permit_type',
	'check_in_date',
	'check_out_date',
	'days',
	'permit_number',
	'crew_name',
	'service_date',
	'service_time',
	'requested_dock',
	'contact_email',
	'contact_number',
	'covid_test_type',
	'requested_service_clean',
	'requested_service_beauty',
	'mode_beauty',
	'requested_service_generic',
	'garbage_declaration',
	'garbage_quantity',
	'service_place',
	'pickup_date',
	'doctor',
	'patient',
	'place_medical',
	'place_details_medical',
	'covid_test_number_of_crew',
	'covid_test_number_of_guest',
	'structure_name',
	'pickup_site',
	'drop_off_date',
	'drop_off_site',
	'available',
	'pax_number',
	'requested_service_ticket',
	'return',
	'return_date',
	'return_time',
	'vehicles_number',
	'required_vehicle',
	'crew_guest',
	'client_name',
	'flight_details',
	'reservation_code',
	'requested_hour_service_clean',
	'payment_type',
	'port',
	'check_in_time',
	'check_out_time',
	'note',
	'service_type',
	'service_details',

	//'createdAt',
	// 'updatedAt',
];

const ShippingFields = [
	'collected_from',
	'collected_to',
	'type_shipping_collected_date',
	'country',
	'courier_price',
	'customer_price',
	'details',
	'duty_paid',
	'eta',
	'handling',
	'invoice_value',
	'item',
	'markup',
	'note',
	'parcel_for_you',
	'pre_alert',
	'price',
	'receiver',
	'requested',
	'sender',
	'storage',
	'courier_tracking_number',
	'unimare_price',
	'date',
	'UE',
];

const Courier = ['id_courier'];
const ShippingStatus = ['id_shipping_status'];
const ShippingType = ['id_shipping_type'];

const CommonReservationsAgency = [
	'reservation_type',
	'port',
	'check_in_date',
	'check_in_time',
	'service_date',
	'service_time',
	'requested_service_generic',
	'pax_number',
	'crew_guest',
	'crew_name',
	'garbage_quantity',
	'service_place',
	'check_out_date',
	'check_out_time',
	'requestested_dock',
	'contact_email',
	'contact_number',
	'payment_type',
	'note',
];
const Procedures = ['formalities', 'garbage', 'isps'];
export const NewShippingDetailFields = [
	{
		type: 'text',
		label: '',
		key: 'attributes.id_shipping.data.attributes.id_shipping_type.data.attributes.shipping_type',
		xs: 12,
		sm: 6,
		mb: 4,
	},
	{
		type: 'text',
		label: '',
		key: 'attributes.status_operation_booking',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Courier',
		key: 'attributes.id_shipping.data.attributes.id_courier.data.attributes.name',
		xs: 12,
	},
	{
		type: 'text',
		label: 'Tracking Number',
		key: 'attributes.id_shipping.data.attributes',
		xs: 12,
		mb: 2,
	},
	{
		type: 'text',
		label: 'Date',
		key: 'attributes.requested_date',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Date',
		key: 'attributes.check_out_date',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Sender',
		key: 'attributes.id_shipping.data.attributes.sender',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Storage',
		key: 'attributes.id_shipping.data.attributes.storage',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Invoice Value',
		key: 'attributes.id_shipping.data.attributes.invoice_value',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Duty Paid',
		key: 'attributes.id_shipping.data.attributes.duty_paid',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Pre Alert',
		key: 'attributes.id_shipping.data.attributes.is_pre_alert',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'UE',
		key: 'attributes.id_shipping.data.attributes.UE',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Details',
		key: 'attributes.id_shipping.data.attributes.details',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Parcel For You',
		key: 'attributes.id_shipping.data.attributes.is_parcel_for_you',
		xs: 12,
		sm: 6,
	},

	{
		type: 'text',
		label: 'From',
		key: 'attributes.id_shipping.data.attributes.collected_from',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'To',
		key: 'attributes.id_shipping.data.attributes.collected_to',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Requested By',
		key: 'attributes.id_shipping.data.attributes.requested',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Country',
		key: 'attributes.id_shipping.data.attributes.country',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Note Shipping',
		key: 'attributes.id_shipping.data.attributes.note',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Operation Date',
		key: 'attributes.operation_date',
		xs: 12,
		mt: 2,
		mb: 2,
	},
	{
		type: 'text',
		label: 'User in Charge',
		key: 'attributes.id_user_in_charge.data.attributes.id_users_permissions_user.data',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Supplier',
		key: 'attributes.supplier_correspondent',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Selling Price',
		key: 'attributes.selling_price',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Purchase Price',
		key: 'attributes.purchase_price',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Account CH/BOAT',
		key: 'attributes.account_type',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Note',
		key: 'attributes.note',
		xs: 12,
		mt: 2,
	},


]

export const DetailShippingType = ShippingType.map((item) => ({
	type: 'text',
	label: _.upperFirst(_.lowerCase(item)),
	key: `attributes.id_shipping.data.attributes.${item}.data.attributes.shipping_type`,
	xs: 12,
	sm: 6,
	md: 4,
}));

export const DetailShippingStatus = ShippingStatus.map((item) => ({
	type: 'text',
	label: _.upperFirst(_.lowerCase(item)),
	key: `attributes.id_shipping.data.attributes.${item}.data.attributes.shipping_status`,
	xs: 12,
	sm: 6,
	md: 4,
}));

export const DetailCourier = Courier.map((item) => ({
	type: 'text',
	label: _.upperFirst(_.lowerCase(item)),
	key: `attributes.id_shipping.data.attributes.${item}.data.attributes.name`,
	xs: 12,
	sm: 6,
	md: 4,
}));

export const DetailShipping = ShippingFields.map((item) => {
	return {
		type: 'text',
		label: _.upperFirst(_.lowerCase(item)),
		key: `attributes.id_shipping.data.attributes.${item}`,
		xs: 12,
		sm: 6,
		md: 4,
	};
});
export const DocumentsShippingDetails = [
	{
		type: 'download',
		label: 'Documents',
		subLabel: 'Documents',
		key: 'attributes.id_shipping.data.attributes.documents.data',
		xs: 12,
	},
];
export const FormaliesDetails = Procedures.map((item) => ({
	type: 'text',
	label: _.upperFirst(_.lowerCase(item)),
	key: `attributes.id_yacht.data.attributes.id_attendance.data[0].attributes.${item}`,
	xs: 12,
	sm: 6,
	md: 4,
}));
export const DetailArrDep = CommonReservationsAgency.map((item) => ({
	type: 'text',
	label: _.upperFirst(_.lowerCase(item)),
	key: `attributes.id_reservation.data.attributes.${item}`,
	xs: 12,
	sm: 6,
}));
export const DetailReservation = ReservationFields.map((item) => ({
	type: 'text',
	label: _.upperFirst(_.lowerCase(item)),
	key: `attributes.id_reservation.data.attributes.${item}`,
	xs: 12,
	sm: 6,
}));

export const DetailReservationPilotiIn = ReservationFields.map((item) => ({
	type: 'text',
	label: _.upperFirst(_.lowerCase(item)),
	key: `attributes.id_reservation_piloti_in.data.attributes.${item}`,
	xs: 12,
	sm: 6,
}));

export const DetailReservationPilotiOut = ReservationFields.map((item) => ({
	type: 'text',
	label: _.upperFirst(_.lowerCase(item)),
	key: `attributes.id_reservation_piloti_out.data.attributes.${item}`,
	xs: 12,
	sm: 6,
}));


export const OperationFields = [
	{
		type: 'text',
		label: 'Agente NA',
		key: 'attributes.id_yacht.data.attributes.id_attendance.data[0].attributes.id_agent_NA.data.attributes.id_users_permissions_user.data',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'User in Charge',
		key: 'attributes.id_user_in_charge.data.attributes.id_users_permissions_user.data',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Operation Date',
		key: 'attributes.operation_date',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Supplier',
		key: 'attributes.supplier_correspondent',
		xs: 12,
		sm: 6,
	},
	// {
	//   type: 'text',
	//   label: 'Supplier',
	//   key: 'attributes.id_supplier_operation.data.attributes.description_supplier',
	//   xs: 12,
	//   sm: 6,
	// },
	{
		type: 'text',
		label: 'Location',
		key: 'attributes.id_marina.data',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Status operation booking',
		key: 'attributes.status_operation_booking',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Account CH/BOAT',
		key: 'attributes.account_type',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Note',
		key: 'attributes.note',
		xs: 12,
		sm: 12,
	},
	{
		type: 'boolean',
		label: 'Is Cagliari',
		key: 'attributes.is_cagliari',
		xs: 12,
		sm: 4,
	},
	{
		type: 'boolean',
		label: 'Invoiced',
		key: 'attributes.ft',
		xs: 12,
		sm: 4,
	},
];

export const OperationDepotFields = [
	{
		type: 'text',
		label: 'Agente NA',
		key: 'attributes.id_yacht.data.attributes.id_attendance.data[0].attributes.id_agent_NA.data.attributes.id_users_permissions_user.data',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'User in Charge',
		key: 'attributes.id_user_in_charge.data.attributes.id_users_permissions_user.data',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Operation Date',
		key: 'attributes.operation_date',
		xs: 12,
		sm: 6,
	},
	// {
	//   type: 'text',
	//   label: 'Supplier',
	//   key: 'attributes.supplier_correspondent',
	//   xs: 12,
	//   sm: 6,
	// },
	{
		type: 'text',
		label: 'Status operation booking',
		key: 'attributes.status_operation_booking',
		xs: 12,
		sm: 6,
	},

	{
		type: 'text',
		label: 'Contact',
		key: 'attributes.contact',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'E-mail / Whatsapp',
		key: 'attributes.type_of_contact',
		xs: 12,
		sm: 6,
	},

	{
		type: 'text',
		label: 'Note',
		key: 'attributes.note',
		xs: 12,
		sm: 12,
	},
];

export const DatesFields = [
	{
		type: 'text',
		label: 'Request Date',
		key: 'attributes.requested_date',
		xs: 12,
		sm: 6,
		md: 4,
	},
	{
		type: 'text',
		label: 'Request Hour',
		key: 'attributes.requested_hour',
		xs: 12,
		sm: 6,
		md: 4,
	},
	{
		type: 'text',
		label: 'Check out date',
		key: 'attributes.check_out_date',
		xs: 12,
		sm: 6,
		md: 4,
	},
];

export const DatesDepotFields = [
	{
		type: 'text',
		label: 'Order Date',
		key: 'attributes.id_date_in',
		xs: 12,
		sm: 6,
		md: 4,
	},
	{
		type: 'text',
		label: 'Request Hour',
		key: 'attributes.requested_hour',
		xs: 12,
		sm: 6,
		md: 4,
	},
	{
		type: 'text',
		label: 'Delivery Date',
		key: 'attributes.id_date_out',
		xs: 12,
		sm: 6,
		md: 4,
	},
];

export const DatesProvisionsFields = [
	{
		type: 'text',
		label: 'Delivery Date',
		key: 'attributes.delivery_date',
		xs: 12,
		sm: 6,
		md: 4,
	},
	{
		type: 'text',
		label: 'Delivery Hour',
		key: 'attributes.delivery_hour',
		xs: 12,
		sm: 6,
		md: 4,
	},
	{
		type: 'text',
		label: 'Hour Type',
		key: 'attributes.hour_type',
		xs: 12,
		sm: 4,
	},
];

export const SellingFields = [
	{
		type: 'text',
		label: 'Selling Price',
		key: 'attributes.selling_price',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Purchase Price',
		key: 'attributes.purchase_price',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Note',
		key: 'attributes.note',
		xs: 12,
		sm: 12,
	},
];

export const MexalFields = [
	{
		type: 'text',
		label: 'Mexal person in charge',
		key: 'attributes.id_mexal_person_in_charge.data.attributes.id_users_permissions_user.data',
		xs: 12,
		sm: 6,
		md: 4,
	},
	{
		type: 'text',
		label: 'OC BC number',
		key: 'attributes.OC_BC_number',
		xs: 12,
		sm: 6,
		md: 4,
	},
	{
		type: 'text',
		label: 'Status operation BC',
		key: 'attributes.status_operation_BC',
		xs: 12,
		sm: 6,
		md: 4,
	},
	{
		type: 'text',
		label: 'OF BF number',
		key: 'attributes.OF_BF_number',
		xs: 12,
		sm: 6,
		md: 4,
	},
	{
		type: 'text',
		label: 'Status operation BF',
		key: 'attributes.status_operation_BF',
		xs: 12,
		sm: 6,
		md: 4,
	},
	{
		type: 'text',
		label: 'OC fees',
		key: 'attributes.OC_fees',
		xs: 12,
		sm: 6,
		md: 4,
	},
];

export const ServiceFields = [
	// {
	//   type: 'text',
	//   label: 'Service',
	//   key: 'attributes.id_service.data.attributes.service',
	//   xs: 12,
	//   sm: 6,
	// },
	{
		type: 'text',
		label: 'Service',
		key: 'attributes.operation_service',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Service Detail',
		key: 'attributes.service_detail',
		xs: 12,
		sm: 12,
		md: 6,
	},
];

export const InfoFields = [
	{
		type: 'text',
		label: 'Yacht Name',
		key: 'attributes.id_yacht.data',
		xs: 12,
		sm: 6,
		mb: 1.5,
	},
	{
		type: 'text',
		label: 'Agent NA',
		key: 'attributes.id_yacht.data.attributes.id_attendance.data[0].attributes.id_agent_NA.data.attributes.id_users_permissions_user.data',
		xs: 12,
		sm: 6,
	},
]

export const NewServiceFields = [
		{
			type: 'text',
			label: '',
			key: 'attributes.id_reservation.data.attributes.reservation_type',
			xs: 12,
		sm: 6,
		mb: 4,
		},
		{
			type: 'text',
			label: '',
			key: 'attributes.status_operation_booking',
			xs: 12,
			sm: 6,
			mb: 4,
		},
		{
			type: 'text',
			label: 'Arrival & Departure Formalities',
			key: 'attributes.id_reservation.data.attributes.id_arrival_departure_formality.data.attributes',
			xs: 12,
			sm: 6,
	},

		{
			type: 'text',
			label: 'Service',
			key: 'attributes.operation_service',
			xs: 12,
			sm: 6,
	},
		{
			type: 'text',
			label: 'Service Date',
			key: 'attributes.id_reservation.data.attributes.service_date',
			xs: 12,
			sm: 6,
		},
		{
			type: 'text',
			label: 'Check in Date',
			key: 'attributes.id_reservation.data.attributes.check_in_date',
			xs: 12,
			sm: 6,
		},
	{
		type: 'text',
		label: 'Check in Time',
		key: 'attributes.id_reservation.data.attributes.check_in_time',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
			label: 'Request Hour',
			key: 'attributes.requested_hour',
			xs: 12,
			sm: 6,
		},
		{
			type: 'text',
			label: 'Check out date',
			key: 'attributes.check_out_date',
			xs: 12,
			sm: 6,
		}, 
		{
			type: 'text',
		label: 'Check out Date Reservation',
		key: 'attributes.id_reservation.data.attributes.check_out_date',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Check out Time',
		key: 'attributes.id_reservation.data.attributes.check_out_time',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
			label: 'Location',
			key: 'attributes.id_marina.data',
			xs: 12,
			sm: 6,
		},
		{
			type: 'text',
			label: 'Operation Date',
			key: 'attributes.operation_date',
			xs: 12,
			sm: 6,
			mb: 4,
		},

		{
			type: 'text',
			label: 'Crew Name',
			key: 'attributes.id_reservation.data.attributes.crew_name',
			xs: 12,
			sm: 6,
		},
		
		{
			type: 'text',
			label: 'Garbage Quantity',
			key: 'attributes.id_reservation.data.attributes.garbage_quantity',
			xs: 12,
			sm: 6,
		},
		{
			type: 'text',
			label: 'Service Time',
			key: 'attributes.id_reservation.data.attributes.service_time',
			xs: 12,
			sm: 6,
		},
		{
			type: 'text',
			label: 'Service Place',
			key: 'attributes.id_reservation.data.attributes.service_place',
			xs: 12,
			sm: 6,
		},
		{
			type: 'text',
			label: 'Port',
			key: 'attributes.id_reservation.data.attributes.port',
			xs: 12,
			sm: 6,
		},
		{
			type: 'text',
			label: 'Requested Dock',
			key: 'attributes.id_reservation.data.attributes.requested_dock',
			xs: 12,
			sm: 6,
		},
		{
			type: 'text',
			label: 'Contact Mail',
			key: 'attributes.id_reservation.data.attributes.requested_dock',
			xs: 12,
			sm: 6,
		},
		{
			type: 'text',
			label: 'Contact Number',
			key: 'attributes.id_reservation.data.attributes.contact_number',
			xs: 12,
			sm: 6,
		},
		{
			type: 'text',
			label: 'Crew Guest',
			key: 'attributes.id_reservation.data.attributes.crew_guest',
			xs: 12,
			sm: 6,
		},
		{
			type: 'text',
			label: 'Pax Number',
			key: '.id_reservation.data.attributes.pax_number',
			xs: 12,
			sm: 6,
		},
		{
			type: 'text',
			label: 'User in Charge',
			key: 'attributes.id_user_in_charge.data.attributes.id_users_permissions_user.data',
			xs: 12,
			sm: 6,
		},
		{
			type: 'text',
			label: 'Supplier',
			key: 'attributes.supplier_correspondent',
			xs: 12,
			sm: 6,
		},
		{
			type: 'text',
			label: 'Selling Price',
			key: 'attributes.selling_price',
			xs: 12,
			sm: 6,
		},
		{
			type: 'text',
			label: 'Purchase Price',
			key: 'attributes.purchase_price',
			xs: 12,
			sm: 6,
		},
		{
			type: 'text',
			label: 'Account CH/BOAT',
			key: 'attributes.account_type',
			xs: 12,
			sm: 6,
		}, 
		{
			type: 'text',
		label: 'Note',
		key: 'attributes.note',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
			label: 'Payment Type',
			key: 'attributes.id_reservation.data.attributes.payment_type',
			xs: 12,
	},
	{
		type: 'text',
		label: '',
		key: 'attributes.id_reservation_piloti_in.data.attributes.reservation_type',
		xs: 12,
		mt: 4,
		mb: 1
	},
	{
		type: 'text',
		label: 'Port',
		key: 'attributes.id_reservation_piloti_in.data.attributes.port',
		xs: 12,
	},
	{
		type: 'text',
		label: 'Check In Date',
		key: 'attributes.id_reservation_piloti_in.data.attributes.check_in_date',
		xs: 12,
	},
	{
		type: 'text',
		label: 'Check In Time',
		key: 'attributes.id_reservation_piloti_in.data.attributes.check_in_time',
		xs: 12,
	},
	{
		type: 'text',
		label: '',
		key: 'attributes.id_reservation_piloti_out.data.attributes.reservation_type',
		xs: 12,
		mt: 4,
		mb: 1
	},
	{
		type: 'text',
		label: 'Port',
		key: 'attributes.id_reservation_piloti_out.data.attributes.port',
		xs: 12,
	},
	{
		type: 'text',
		label: 'Check Out Date',
		key: 'attributes.id_reservation_piloti_out.data.attributes.check_out_date',
		xs: 12,
	},
	{
		type: 'text',
		label: 'Check Out Time',
		key: 'attributes.id_reservation_piloti_out.data.attributes.check_out_time',
		xs: 12,
	},
	{
		type: 'text',
		label: 'Booking',
		key: 'attributes.id_reservation.data.attributes.id_booking.data',
		xs: 12,
	},


]

export const NewMexalFields = [
		{
			type: 'text',
			label: 'Mexal person in charge',
			key: 'attributes.id_mexal_person_in_charge.data.attributes.id_users_permissions_user.data',
			xs: 12,
			mb: 2,
			mt: 2

		},
		{
			type: 'text',
			label: 'Status operation BC',
			key: 'attributes.status_operation_BC',
			xs: 12,
			sm: 6,
		},
		{
			type: 'text',
			label: 'Status operation BF',
			key: 'attributes.status_operation_BF',
			xs: 12,
			sm: 6,
		},
		{
			type: 'text',
			label: 'OC BC number',
			key: 'attributes.OC_BC_number',
			xs: 12,
			sm: 6,
		},
		{
			type: 'text',
			label: 'OF BF number',
			key: 'attributes.OF_BF_number',
			xs: 12,
			sm: 6,
		},
		{
			type: 'text',
			label: 'OC fees',
			key: 'attributes.OC_fees',
			xs: 12,
			mb: 2
		},
		{
			type: 'boolean',
			label: 'Invoiced',
			key: 'attributes.ft',
			xs: 12,
		},
	]

// concierge detail fields

export const NewConciergeServiceFields = [
	{
		type: 'text',
		label: '',
		key: 'attributes.id_reservation.data.attributes.reservation_type',
		xs: 12,
		sm: 6,
		mb: 4,
	},
	{
		type: 'text',
		label: '',
		key: 'attributes.status_operation_booking',
		xs: 12,
		sm: 6,
		mb: 4,
	},
	{
		type: 'text',
		label: 'Arrival & Departure Formalities',
		key: 'attributes.id_reservation.data.attributes.id_arrival_departure_formality.data.attributes',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Service',
		key: 'attributes.operation_service',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Service Detail',
		key: 'attributes.service_detail',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Service Date',
		key: 'attributes.id_reservation.data.attributes.service_date',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Request Hour',
		key: 'attributes.requested_hour',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Check out date',
		key: 'attributes.check_out_date',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Location',
		key: 'attributes.id_marina.data',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Operation Date',
		key: 'attributes.operation_date',
		xs: 12,
		sm: 6,
		mb: 4,
	},
	{
		type: 'text',
		label: 'Crew Name',
		key: 'attributes.id_reservation.data.attributes.crew_name',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Garbage Quantity',
		key: 'attributes.id_reservation.data.attributes.garbage_quantity',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Service Time',
		key: 'attributes.id_reservation.data.attributes.service_time',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Service Place',
		key: 'attributes.id_reservation.data.attributes.service_place',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Port',
		key: 'attributes.id_reservation.data.attributes.port',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Requested Dock',
		key: 'attributes.id_reservation.data.attributes.requested_dock',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Contact Number',
		key: 'attributes.id_reservation.data.attributes.contact_number',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Crew / Guest',
		key: 'attributes.id_reservation.data.attributes.crew_guest',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Contact',
		key: 'attributes.contact',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'E-Mail / Whatsapp',
		key: 'attributes.type_of_contact',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Pax Number',
		key: '.id_reservation.data.attributes.pax_number',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'User in Charge',
		key: 'attributes.id_user_in_charge.data.attributes.id_users_permissions_user.data',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Supplier',
		key: 'attributes.supplier_correspondent',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Selling Price',
		key: 'attributes.selling_price',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Purchase Price',
		key: 'attributes.purchase_price',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Account CH/BOAT',
		key: 'attributes.account_type',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Payment Type',
		key: 'attributes.id_reservation.data.attributes.payment_type',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Client Name',
		key: 'attributes.id_reservation.data.attributes.client_name',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Requested Service Generic',
		key: 'attributes.id_reservation.data.attributes.requested_service_generic',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Doctor',
		key: 'attributes.id_reservation.data.attributes.doctor',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Test Type',
		key: 'attributes.id_reservation.data.attributes.covid_test_type',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Patient',
		key: 'attributes.id_reservation.data.attributes.patient',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Place',
		key: 'attributes.id_reservation.data.attributes.place_medical',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Permit Type',
		key: 'attributes.id_reservation.data.attributes.permit_type',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Permit Number',
		key: 'attributes.id_reservation.data.attributes.permit_number',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Days',
		key: 'attributes.id_reservation.data.attributes.days',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Start Date',
		key: 'attributes.id_reservation.data.attributes.check_in_date',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'End Date',
		key: 'attributes.id_reservation.data.attributes.check_out_date',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: "Captain's Name",
		key: 'attributes.id_reservation.data.attributes.crew_name',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Pick Up Site',
		key: 'attributes.id_reservation.data.attributes.pickup_site',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Pick Up Date',
		key: 'attributes.id_reservation.data.attributes.pickup_date',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Drop Off Site',
		key: 'attributes.id_reservation.data.attributes.drop_off_site',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Drop Off Date',
		key: 'attributes.id_reservation.data.attributes.drop_off_date',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Type Of Service',
		key: 'attributes.id_reservation.data.attributes.service_type',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Requested Service',
		key: 'attributes.id_reservation.data.attributes.requested_service_ticket',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Return',
		key: 'attributes.id_reservation.data.attributes.return',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Return Date',
		key: 'attributes.id_reservation.data.attributes.return_date',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Return Time',
		key: 'attributes.id_reservation.data.attributes.return_time',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Requested Service',
		key: 'attributes.id_reservation.data.attributes.requested_service_ticket',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Requested Service',
		key: 'attributes.id_reservation.data.attributes.requested_service_beauty',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Mode',
		key: 'attributes.id_reservation.data.attributes.mode_beauty',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Service Details',
		key: 'attributes.id_reservation.data.attributes.service_details',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Available',
		key: 'attributes.id_reservation.data.attributes.available',
		xs: 12,
		sm: 6,
	},

	{
		type: 'text',
		label: 'Pax',
		key: 'attributes.id_reservation.data.attributes.pax_number',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Vehicles Number',
		key: 'attributes.id_reservation.data.attributes.vehicles_number',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Required Vehicle',
		key: 'attributes.id_reservation.data.attributes.required_vehicle',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Flight',
		key: 'attributes.id_reservation.data.attributes.flight_details',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Reservation / Confirm Code',
		key: 'attributes.id_reservation.data.attributes.reservation_code',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Structure Name',
		key: 'attributes.id_reservation.data.attributes.structure_name',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Note',
		key: 'attributes.note',
		xs: 12,
		sm: 6,
	},
]


export const ProductsListFields = [
	{
		type: 'text',
		label: 'OC Number',
		key: 'attributes.id_list.data.attributes.OC_number',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Notes',
		key: 'attributes.id_list.data.attributes.notes',
		xs: 12,
		sm: 6,
	},

	{
		type: 'text',
		label: 'Detail',
		key: 'attributes.id_list_id',
		xs: 12,
		sm: 6,
	},
];
export const NewInteriorsDepotProvisionFields = [
	{
		type: 'text',
		label: '',
		key: 'attributes.id_service.data.attributes.service',
		xs: 12,
		sm: 6,
		mb: 4,
	},
	{
		type: 'text',
		label: '',
		key: 'attributes.status_operation_booking',
		xs: 12,
		sm: 6,
		mb: 4,
	},
	{
		type: 'text',
		label: 'Delivery Date',
		key: 'attributes.requested_date',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Delivery Time',
		key: 'attributes.requested_hour',
		xs: 12,
		sm: 4,
	},
	{
		type: 'text',
		label: 'Hour Type',
		key: 'attributes.hour_type',
		xs: 12,
		sm: 2,
	},
	{
		type: 'text',
		label: 'Contact',
		key: 'attributes.contact',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'E-Mail / Whatsapp',
		key: 'attributes.type_of_contact',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Location',
		key: 'attributes.id_marina.data',
		xs: 12,

	},
	{
		type: 'text',
		label: 'Detail',
		key: 'attributes.id_list_id',
		xs: 12,
		mb: 4,
		mt: 4,
	},
	{
		type: 'text',
		label: 'Operation Date',
		key: 'attributes.operation_date',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Note',
		key: 'attributes.note',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'User in Charge',
		key: 'attributes.id_user_in_charge.data.attributes.id_users_permissions_user.data',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Supplier',
		key: 'attributes.supplier_correspondent',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Selling Price',
		key: 'attributes.selling_price',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Purchase Price',
		key: 'attributes.purchase_price',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Account CH/BOAT',
		key: 'attributes.account_type',
		xs: 12,
		sm: 6,
	},

]
export const NewTechnicalDetailFields = [
	{
		type: 'text',
		label: '',
		key: 'attributes.id_service.data.attributes.service',
		xs: 12,
		sm: 6,
		mb: 4,
	},
	{
		type: 'text',
		label: '',
		key: 'attributes.status_operation_booking',
		xs: 12,
		sm: 6,
		mb: 4,
	},
	{
		type: 'text',
		label: 'Delivery Date',
		key: 'attributes.requested_date',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Delivery Time',
		key: 'attributes.requested_hour',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Contact',
		key: 'attributes.contact',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'E-Mail / Whatsapp',
		key: 'attributes.type_of_contact',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Location',
		key: 'attributes.id_marina.data',
		xs: 12,

	},
	{
		type: 'text',
		label: 'Detail',
		key: 'attributes.id_list_id',
		xs: 12,
		mb: 4,
		mt: 4,
	},
	{
		type: 'text',
		label: 'Operation Date',
		key: 'attributes.operation_date',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Note',
		key: 'attributes.note',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'User in Charge',
		key: 'attributes.id_user_in_charge.data.attributes.id_users_permissions_user.data',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Supplier',
		key: 'attributes.supplier_correspondent',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Selling Price',
		key: 'attributes.selling_price',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Purchase Price',
		key: 'attributes.purchase_price',
		xs: 12,
		sm: 6,
	},
	{
		type: 'text',
		label: 'Account CH/BOAT',
		key: 'attributes.account_type',
		xs: 12,
		sm: 6,
	},
]

export const TechnicalInfoFields = [
	{
		type: 'text',
		label: 'Yacht Name',
		key: 'attributes.id_yacht.data',
		xs: 12,
		sm: 6,
		mb: 1.5,
	},
	{
		type: 'text',
		label: 'Agent NA',
		key: 'attributes.id_yacht.data.attributes.id_attendance.data[0].attributes.id_agent_NA.data.attributes.id_users_permissions_user.data',
		xs: 12,
		sm: 6,
	},
]

export const DetailConf = {
	initialState: {
		loading: false,
		error: null,
		data: null,
		meta: null,
	},
	fields: [
		{
			...YachtFields,
			items: [
				{
					type: 'text',
					label: 'Yacht name',
					key: 'attributes.id_yacht.data',
					xs: 12,
				},
				...YachtFields.items.map(
					(item) =>
						item.key !== 'attributes.yacht_name' && {
							...item,
							key: `attributes.id_yacht.data.${item.key}`,
						}
				),
			],
		},
		{
			id: 'service_section',
			type: 'section',
			label: 'Service',
			items: ServiceFields,
		},
		{
			id: 'dates_section',
			type: 'section',
			label: 'Dates',
			items: DatesFields,
		},
		{
			id: 'operation_section',
			type: 'section',
			label: 'Operation Detail',
			items: OperationFields,
		},
		{
			id: 'operation_section',
			type: 'section',
			label: 'Price',
			items: SellingFields,
		},
		{
			id: 'operation_section',
			type: 'section',
			label: 'Mexal info',
			items: MexalFields,
		},
	],
	formatValue: (key, value) => {
		switch (key) {
			//SHIPPING
			case 'attributes.id_shipping.data.attributes.date':
				return (
					<Text
						label='date'
						value={value ? moment(value).format('DD-MM-YYYY') : '-'}
					/>
				);

			case 'attributes.id_shipping.data.attributes.type_shipping_collected_date':
				const date = value.collected_date
					? moment(value.collected_date).format('DD-MM-YYYY')
					: null;
				const label =
					value.id_shipping_type === 'STORAGE'
						? 'Date Out'
						: value.collected_date && 'Collected Date';
				return <Text label={label} value={date} sx={{ display: 'none' }} />;

			case 'attributes.id_shipping.data.attributes.id_courier.data.attributes.name':
				return <Text label='Courier' value={value ? value : '-'} />;
			case 'attributes.id_shipping.data.attributes.id_shipping_status.data.attributes.shipping_status':
				return <Text label='Shipping Status' value={value ? value : '-'} />;

			case 'attributes.id_shipping.data.attributes.id_shipping_type.data.attributes.shipping_type':
				const bgColor2 = _.find(
					ShippingOptions,
					(item) => item.label.toUpperCase() === value.toUpperCase()
				)
				return (
					<RectangleChip
						width='auto'
						value={value}
						color='white'
						backgroundColor={bgColor2?.color ? bgColor2.color : '#009ab4'}
					/>
				);


			case 'attributes.id_reservation.data.attributes.id_arrival_departure_formality.data.attributes':
				return <Text label={value.reservation_type} value={value.value ? value.value : '-'} />;
			case 'attributes.id_shipping.data.attributes.documents.data':
				return (
					<Grid
						container
						spacing={1}
						width={'100%'}
						xs={{ justifyContent: 'center' }}
						mt={2}
						mb={1}
					>
						{value?.map((item) => (
							<Grid item xs={12} md={6} lg={4}>
								<DownlaodComponent
									key={item.id}
									value={{
										title: _.get(item, 'attributes.name'),
										url: _.get(item, 'attributes.url'),
									}}
									tag='yachts'
								/>
							</Grid>
						))}
					</Grid>
				);

			case 'attributes.id_shipping.data.attributes':
				const disabled =
					process.env.REACT_APP_TRACKING_URL && value?.id_courier;
				return value?.tracking_number ? (
					<Text
						label='Tracking Number'
						value={
							<Link
								style={{
									color: !disabled ? 'grey' : 'blue',
									pointerEvents: !disabled ? 'none' : 'auto',
								}}
								to={`${process.env.REACT_APP_TRACKING_URL}${value.tracking_number}`}
								target='_blank'
							>
								{value?.tracking_number}
							</Link>
						}
					/>
				) : (
					<></>
				);
			case 'attributes.id_reservation.data.attributes.id_booking.data':
				return (
					<Text
						label='Booking'
						value={
							<LinkPermission
								to={`/booking/${value?.id}`}
								value={`${value?.id}`}
								page='BOOKING'
							/>
						}
					/>
				);

			//-----
			case 'attributes.id_yacht.data':
				return (
					<Text
						label='Yacht name'
						value={
							<LinkPermission
								to={`/yachts/${value?.id}`}
								value={value?.attributes?.yacht_name}
								page='YACHTS'
							/>
						}
					/>
				);


			case 'attributes.id_yacht.data.attributes.draft':
				return <Text label='Draft' value={_.isEmpty(value) ? `${value} m` : '-'} />;

			case 'attributes.id_yacht.data.attributes.loa':
				return <Text label='Loa' value={_.isEmpty(value) ? `${value} m` : '-'} />;

			case 'attributes.id_yacht.data.attributes.beam':
				return <Text label='Beam' value={_.isEmpty(value) ? `${value} m` : '-'} />;
			case 'attributes.operation_date':
				return <Text label='Operation Date' value={moment(value).format('DD-MM-YYYY')} />;
			case 'attributes.id_list_id':
				const id = _.last(_.split(window.location.href, '/'));

				switch (value?.id_department) {
					case 'DEP':
						return (
							<LinkProductsList
								label={'ITEMS LIST'}
								to={`list/${value?.id_list}`}
								state={{
									id_operation: id,
								}}
								sx={{ backgroundColor: '#009ab6' }}
								variant='contained'
								page='DEPOT'
							/>
						);
					case 'PRV':
						return (
							<LinkProductsList
								label={'ITEMS LIST'}
								to={`list/${value?.id_list}`}
								state={{
									id_operation: id,
								}}
								sx={{ backgroundColor: '#009ab6' }}
								variant='contained'
								page='PROVISIONS'
							/>
						);
					case 'INT':
						return (
							<LinkProductsList
								label={'ITEMS LIST'}
								to={`list/${value?.id_list}`}
								state={{
									id_operation: id,
								}}
								sx={{ backgroundColor: '#009ab6' }}
								variant='contained'
								page='INTERIORS'
							/>
						);
					default:
						return <></>;
				}

			case 'attributes.requested_hour':
				return <Text label='Time' value={value ? moment(value, 'HH:mm:ss').format('HH:mm') : '-'} />;
			case 'attributes.id_reservation.data.attributes.service_time':
				return <Text label='Service Time' value={value ? moment(value, 'HH:mm:ss').format('HH:mm') : '-'} />;


			case 'attributes.status_operation_BF':
				return value ? (
					<Text
						label={'Status Operation BF'}
						value={value === 'OPEN' ? <CancelIcon color='error' /> : value === 'CLOSED' ? <CheckCircleIcon color='success' /> : <DoNotDisturbIcon color='disabled' />}
					/>
				) : null;
			case 'attributes.status_operation_BC':
				return value ? (

					<Text
						label={'Status Operation BC'}
						value={value === 'OPEN' ? <CancelIcon color='error' /> : value === 'CLOSED' ? <CheckCircleIcon color='success' /> : <DoNotDisturbIcon color='disabled' />}
					/>
				) : null;

			case 'attributes.createdAt':
			case 'attributes.id_reservation.data.attributes.createdAt':
			case 'attributes.id_reservation_piloti_in.data.attributes.createdAt':
			case 'attributes.id_reservation_piloti_out.data.attributes.createdAt':
			case 'attributes.id_technical_work.data.attributes.createdAt':
				return <Text label='Created At' value={moment(value).format('DD-MM-YYYY')} />;
			case 'attributes.updatedAt':
			case 'attributes.id_reservation.data.attributes.updatedAt':
			case 'attributes.id_reservation_piloti_in.data.attributes.updatedAt':
			case 'attributes.id_reservation_piloti_out.data.attributes.updatedAt':
			case 'attributes.id_technical_work.data.attributes.updatedAt':
				return <Text label='Updated At' value={moment(value).format('DD-MM-YYYY')} />;
			case 'attributes.id_reservation_piloti_in.data.attributes.service_date':
			case 'attributes.id_reservation_piloti_out.data.attributes.service_date':
			case 'attributes.id_technical_work.data.attributes.service_date':
				return <Text label='Date' value={value ? moment(value).format('DD-MM-YYYY') : '-'} />;
			case 'attributes.id_reservation.data.attributes.service_date':
				return <Text label='Service Date' value={value ? moment(value).format('DD-MM-YYYY') : '-'} />;
			case 'attributes.id_reservation.data.attributes.check_in_date':
			case 'attributes.id_reservation_piloti_in.data.attributes.check_in_date':
			case 'attributes.id_reservation_piloti_out.data.attributes.check_in_date':
			case 'attributes.id_technical_work.data.attributes.check_in_date':
				return <Text label='Check in date' value={value ? moment(value).format('DD-MM-YYYY') : '-'} />;
			case 'attributes.id_reservation.data.attributes.check_out_date':
				return <Text label='Check out Date Reservation' value={value ? moment(value).format('DD-MM-YYYY') : '-'} />;
			case 'attributes.check_out_date':
			case 'attributes.id_reservation_piloti_in.data.attributes.check_out_date':
			case 'attributes.id_reservation_piloti_out.data.attributes.check_out_date':
			case 'attributes.id_technical_work.data.attributes.check_out_date':
				return <Text label='Check out date' value={value ? moment(value).format('DD-MM-YYYY') : '-'} />;

			case 'attributes.id_reservation.data.attributes.drop_off_date':
			case 'attributes.id_reservation_piloti_in.data.attributes.drop_off_date':
			case 'attributes.id_reservation_piloti_out.data.attributes.drop_off_date':
			case 'attributes.id_technical_work.data.attributes.drop_off_date':
				return <Text label='Drop off date' value={value ? moment(value).format('DD-MM-YYYY') : '-'} />;

			case 'attributes.id_reservation.data.attributes.pickup_date':
			case 'attributes.id_reservation_piloti_in.data.attributes.pickup_date':
			case 'attributes.id_reservation_piloti_out.data.attributes.pickup_date':
			case 'attributes.id_technical_work.data.attributes.pickup_date':
				return <Text label='Pickup date' value={value ? moment(value).format('DD-MM-YYYY') : '-'} />;

			case 'attributes.id_reservation.data.attributes.return_date':
			case 'attributes.id_reservation_piloti_in.data.attributes.return_date':
			case 'attributes.id_reservation_piloti_out.data.attributes.return_date':
			case 'attributes.id_technical_work.data.attributes.return_date':
				return <Text label='Return date' value={value ? moment(value).format('DD-MM-YYYY') : '-'} />;

			case 'attributes.id_technical_work.data.attributes.work_date':
				return <Text label='Work date' value={value ? moment(value).format('DD-MM-YYYY') : '-'} />;

			case 'attributes.id_technical_work.data.attributes.time_in':
				return <Text label='Time in' value={value ? moment(value, 'HH:mm:ss').format('HH:mm') : '-'} />;
			case 'attributes.id_technical_work.data.attributes.time_out':
				return <Text label='Time out' value={value ? moment(value, 'HH:mm:ss').format('HH:mm') : '-'} />;

			case 'attributes.id_reservation.data.attributes.check_in_time':
			case 'attributes.id_reservation_piloti_in.data.attributes.check_in_time':
			case 'attributes.id_reservation_piloti_out.data.attributes.check_in_time':
				return <Text label='Check in time' value={value ? moment(value, 'HH:mm:ss').format('HH:mm') : '-'} />;

			case 'attributes.id_reservation.data.attributes.check_out_time':
			case 'attributes.id_reservation_piloti_in.data.attributes.check_out_time':
			case 'attributes.id_reservation_piloti_out.data.attributes.check_out_time':
				return <Text label='Check out time' value={value ? moment(value, 'HH:mm:ss').format('HH:mm') : '-'} />;

			case 'attributes.id_reservation.data.attributes.return_time':
			case 'attributes.id_reservation_piloti_in.data.attributes.return_time':
			case 'attributes.id_reservation_piloti_out.data.attributes.return_time':
				return <Text label='Return time' value={value ? moment(value, 'HH:mm:ss').format('HH:mm') : '-'} />;


			case 'attributes.id_reservation_piloti_in.data.attributes.service_time':
			case 'attributes.id_reservation_piloti_out.data.attributes.service_time':
				return <Text label='Service time' value={value ? moment(value, 'HH:mm:ss').format('HH:mm') : '-'} />;
			/////////___________________________________
			case 'attributes.id_mexal_person_in_charge.data.attributes.id_users_permissions_user.data':
				return (
					<Text
						label='Mexal person in charge'
						value={
							<LinkPermission
								to={`/contacts/${value?.id}`}
								value={`${value?.attributes?.name} ${value?.attributes?.surname}`}
								page='CONTACTS_NAUTICA'
							/>
						}
					/>
				);
			/////////___________________________________
			case 'attributes.id_marina.data':
				return (
					<Text
						label='Location'

						value={`${value?.attributes?.location} - ${value?.attributes?.marina}`}


					/>
				);

			case 'attributes.id_technical_work.data.attributes.id_user_technicians.data.attributes.id_users_permissions_user.data':
				return (
					<Text
						label='User Technicians'
						value={
							<LinkPermission
								to={`/contacts/${value?.id}`}
								value={`${value?.attributes?.name} ${value?.attributes?.surname}`}
								page='CONTACTS_NAUTICA'
							/>
						}
					/>
				);

			case 'attributes.id_user_in_charge.data.attributes.id_users_permissions_user.data':
				return (
					<Text
						label='User in Charge'
						value={
							<LinkPermission
								to={`/contacts/${value?.id}`}
								value={`${value?.attributes?.name} ${value?.attributes?.surname}`}
								page='CONTACTS_NAUTICA'
							/>
						}
					/>
				);
			case 'attributes.id_reservation_piloti_out.data.attributes.reservation_type':
			case 'attributes.id_reservation_piloti_in.data.attributes.reservation_type':
				return (
					<Text
						label=''
						value={value}
						sx={{ fontWeight: 'bold' }}
					/>
				);

			case 'attributes.id_yacht.data.attributes.id_attendance.data[0].attributes.id_agent_NA.data.attributes.id_users_permissions_user.data':
				return (
					<Text
						label='Agente NA'
						value={
							<LinkPermission
								to={`/contacts/${value?.id}`}
								value={`${value?.attributes?.name} ${value?.attributes?.surname}`}
								page='CONTACTS_NAUTICA'
							/>
						}
					/>
				);

			//TECHNICAL
		
			case 'attributes.id_list.data.attributes.check_depot':
				return value ? (
					<Text
						label={'Done'}
						value={value === 'CLOSED' ? <CancelIcon color='error' /> : <CheckCircleIcon color='success' />}
					/>
				) : null;

			case 'attributes.id_date_in':
				return (
					<Text
						label={value?.id_department === 'DEP' ? 'Order Date' : 'Date In'}
						value={
							value?.date_in ? moment(value?.date_in).format('DD-MM-YYYY') : '-'
						}
					/>
				);
			case 'attributes.id_date_out':
				return (
					<Text
						label={
							value?.id_department === 'DEP' ? 'Delivery Date' : 'Date Out'
						}
						value={
							value?.date_out
								? moment(value?.date_out).format('DD-MM-YYYY')
								: '-'
						}
					/>
				);
			case 'attributes.date_in':
				return (
					<Text
						label='Date In'
						value={value ? moment(value).format('DD-MM-YYYY') : '-'}
					/>
				);


			case 'attributes.requested_date':
				return (
					<Text
						label='Request Date'
						value={value ? moment(value).format('DD-MM-YYYY') : '-'}
					/>
				);

			case 'attributes.id_reservation.data.attributes.reservation_type':
				const bgColor = _.find(
					AgencyOptions,
					(item) => item.label.toUpperCase() === value.toUpperCase()
				)
				return (
					<RectangleChip 
					width='auto'
					value={value}
					color='white'
					backgroundColor={bgColor?.color ? bgColor?.color : '#009ab4'}
					/>
				);
			case 'attributes.id_service.data.attributes.service':
				const operationServiceColors = _.find(
					ServiceOptions,
					(item) => item.label.toUpperCase() === value.toUpperCase()
				)
				return (
					<RectangleChip
						width='auto'
						value={value}
						color='white'
						backgroundColor={operationServiceColors?.color ? operationServiceColors?.color : '#009ab4'}
					/>
				);
			case 'attributes.status_operation_booking':
				const color = _.find(
					Status,
					(item) => item.label.toUpperCase() === value.toUpperCase()
				  );
				return (
					<RectangleChip 
					width='auto'
					value={value}
					color='white'
					backgroundColor={color.hex}
					/>
				);
			default:
				return null;
		}
	},
};
