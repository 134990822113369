import React, { Fragment, useEffect, useState } from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../components/common/Loading.component';
import DetailView from '../../../views/Detail.view';
import Section from '../../../components/detail/components/Section.component';
import _ from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import TechnicalWorkDeletePage from '../delete/TechnicalWorkDelete.page';
import { v4 as uuidv4 } from 'uuid';
import IconDownlaodComponent from '../../../components/detail/components/IconDownload.component';

const EditButton = ({ to }) => {
  const { id } = useParams();

  return (
    <Link to={to} state={{ id: id }}>
      <IconButton color='primary'>
        <EditIcon />
      </IconButton>
    </Link>
  );
};

const TechnicalWorkDetailPage = ({ module, technicalModule }) => {
  const { detail, tag } = module;
  const {
    loading,
    data: dataTech,
    error,
  } = useSelector(technicalModule.detail.selector.data);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const userAuth = useSelector((state) => state.login?.user);

  useEffect(() => {
    if (dataTech?.attributes?.id_technical_works?.data)
      setData(dataTech?.attributes?.id_technical_works?.data);
  }, [dataTech]);

  useEffect(() => {
    if (
      error &&
      (error?.response?.status === 404 || error?.response?.status === 500)
    )
      navigate('/404');
  }, [error, navigate]);

  return (
    <Fragment>
      {loading && <Loading />}
      {userAuth.permissions[_.toUpper(tag)]?.READ === 'true' && (
        <Box mt={2}>
          <Section label='Reports'>
            {data?.map((value) => {
              const fields = detail.conf?.fields.map((item) => {
                const newField = _.cloneDeep(item);
                if (item?.label === 'Report Number')
                  _.set(
                    newField,
                    'label',
                    `Report Number: ${value?.attributes?.report_number}`
                  );
                return newField;
              });

              return (
                <Box key={uuidv4()} mb={2} mt={1} sx={{ position: 'relative' }}>
                  <Stack
                    direction={'row'}
                    mt={1.7}
                    sx={{ position: 'absolute', top: 0, right: 0 }}
                  >
                    {value?.attributes?.work_date &&
                      !_.isEmpty(
                        value?.attributes?.yacht_crew_signature?.data
                      ) &&
                      !_.isEmpty(
                        value?.attributes?.na_tech_signature?.data
                      ) && (
                        <IconDownlaodComponent
                          value={value}
                          module={module}
                          title={`Download PDF of Report ${value?.attributes?.report_number}`}
                        />
                      )}
                    {detail?.edit &&
                      userAuth.permissions[_.toUpper(tag)]?.UPDATE ===
                        'true' && (
                        <EditButton to={`technicalWork/${value?.id}/edit`} />
                      )}
                    {userAuth.permissions[_.toUpper(tag)]?.DELETE ===
                      'true' && (
                      <TechnicalWorkDeletePage module={module} id={value?.id} />
                    )}
                  </Stack>

                  <DetailView
                    data={value}
                    conf={{
                      ...detail.conf,
                      fields: fields,
                    }}
                  />
                </Box>
              );
            })}
          </Section>
        </Box>
      )}
    </Fragment>
  );
};

export default TechnicalWorkDetailPage;
