import dayjs from 'dayjs';
import _ from 'lodash';
export const API = {
  technical: {
    path: '/operation-lists?filters[id_department][short_description][$eq]=TECH&populate[id_yacht][populate][id_attendance][populate][id_agent_NA][populate][id_users_permissions_user]=%2A&populate[id_department]=%2A&populate[id_marina]=%2A&populate[id_service]=%2A&populate[id_user_in_charge][populate][id_users_permissions_user][fields]=name,surname',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
    transform: (data) => ({
      meta: data?.meta,
      data: data?.data.map((item) => ({
        id: item?.id,
        attributes: {
          ...item?.attributes,
          ft_id: {
            ft: item?.attributes.ft,
            id: item?.id,
          },
        },
      })),
    }),
  },
  operation: {
    path: '/operation-lists/{{id}}?populate[id_technical_works][populate][yacht_crew_signature]=%2A&populate[id_technical_works][populate][na_tech_signature]=%2A&populate[id_yacht][populate][id_attendance][populate][id_agent_NA][populate][id_users_permissions_user][fields][0][1]=name, surname&populate[id_technical_work][populate][id_user_technicians][populate][id_users_permissions_user][fields]=name,surname&populate[id_mexal_person_in_charge][populate][id_users_permissions_user][fields]=name,surname&populate[id_department]=%2A&populate[id_marina]=%2A&populate[id_yacht]=%2A&populate[id_reservation][populate][id_arrival_departure_formality]=%2A&populate[id_user_in_charge][populate][id_users_permissions_user][fields]=name,surname&populate[id_service]=%2A&populate[id_supplier_operation]=%2A&populate[id_reservation_piloti_in][populate][id_yacht]=%2A&populate[id_yacht][populate][id_flag]=%2A&populate[id_technical_works]=%2A&populate[id_user_last_updated][populate][id_users_permissions_user]=%2A&populate[id_technical_works][populate][technical_work_pdf]=%2A',
    method: 'GET',
    get: null,
    auth: true,
    fallback: null,
  },
  update: {
    path: '/operation-lists/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => ({
      data: {
        ...body,
        id_mexal_person_in_charge: body?.id_mexal_person_in_charge?.id,
        type_of_contact: body?.type_of_contact?.id,
        id_technical_work: body?.id_technical_work?.id,
        status_operation_BF: body?.status_operation_BF?.id,
        status_operation_BC: body?.status_operation_BC?.id,
        status_operation_booking: body?.status_operation_booking,
        operation_date: body?.operation_date
          ? dayjs(body?.operation_date).format('YYYY-MM-DD')
          : null,
        requested_date: body?.requested_date
          ? dayjs(body?.requested_date).format('YYYY-MM-DD')
          : dayjs().format('YYYY-MM-DD'),
        requested_hour: body?.requested_hour
          ? dayjs(body?.requested_hour).format('HH:mm:ss')
          : null,
        account_type: body?.account_type?.id,
        OC_fees: _.isEmpty(body?.OC_fees) ? null : body?.OC_fees,
        selling_price: _.isEmpty(body?.selling_price)
          ? null
          : body?.selling_price,
        purchase_price: _.isEmpty(body?.purchase_price)
          ? null
          : body?.purchase_price,
      },
    }),
  },
  delete: {
    path: '/operation-lists/{{id}}',
    method: 'DELETE',
    get: null,
    auth: true,
    fallback: null,
  },
  create: {
    path: '/operation-lists',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => {
      return {
        data: {
          ...body,
          id_mexal_person_in_charge: body?.id_mexal_person_in_charge?.id,
          type_of_contact: body?.type_of_contact?.id,
          id_technical_work: body?.id_technical_work?.id,
          status_operation_BF: body?.status_operation_BF?.id,
          status_operation_BC: body?.status_operation_BC?.id,
          status_operation_booking: body?.status_operation_booking,
          operation_date: body?.operation_date
            ? dayjs(body?.operation_date).format('YYYY-MM-DD')
            : null,
          requested_date: body?.requested_date
            ? dayjs(body?.requested_date).format('YYYY-MM-DD')
            : dayjs().format('YYYY-MM-DD'),
          requested_hour: body?.requested_hour
            ? dayjs(body?.requested_hour).format('HH:mm:ss')
            : null,
          account_type: body?.account_type?.id,
          OC_fees: _.isEmpty(body?.OC_fees) ? null : body?.OC_fees,
          selling_price: _.isEmpty(body?.selling_price)
            ? null
            : body?.selling_price,
          purchase_price: _.isEmpty(body?.purchase_price)
            ? null
            : body?.purchase_price,
        },
      };
    },
  },
  createTechnical: {
    path: '/technical-works',
    method: 'POST',
    get: null,
    auth: true,
    fallback: null,
  },
  updateFromList: {
    path: '/operation-lists/{{id}}',
    method: 'PUT',
    get: null,
    auth: true,
    fallback: null,
    adapter: (body) => ({
      data: {
        ...body,
      },
    }),
  },
};
